/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
// import numeral from 'numeral'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Accordion, ListGroup, ListGroupItem } from 'react-bootstrap'

function AssignTable({ setting }) {
  const { costsErp, invoices = [] } = setting
  const invoiceCampaigns = invoices.reduce(
    (prev, cur) => [
      ...prev,
      ...cur.campaigns.data.map((c) => ({
        ad_account_id: c.ad_account_id,
        campaign_name: c.campaign_name,
        campaign_id: c.campaign_id,
        description: c.description,
        ad_account_name: cur.adaccount,
        currency: c.billed_amount_details.currency,
        net_amount: c.billed_amount_details.net_amount,
      })),
    ],
    []
  )
  console.log(invoiceCampaigns)
  console.log(costsErp)
  const [assigned, setassigned] = useState({})
  const [selected, setselected] = useState({
    name: '',
  })
  console.log(assigned)

  return (
    <Row>
      <Col xs={4}>
        <ListGroup>
          {costsErp[1] &&
            costsErp[1].map((c) => (
              <ListGroupItem
                key={c[0]}
                onClick={() =>
                  setselected({
                    name: c[1],
                  })
                }
              >
                <Row>
                  <Col>{c[1]}</Col>
                  {assigned[c[1]] && (
                    <Col>已分派到{assigned[c[1]].length}項設定</Col>
                  )}
                </Row>
              </ListGroupItem>
            ))}
        </ListGroup>
      </Col>
      <Col xs={4}>
        <Row>
          <Col>{selected.name}</Col>
        </Row>
        <ListGroup>
          {(assigned[selected.name] || []).map((ic, index) => (
            <ListGroupItem key={index}>
              <Row>
                <Col>{ic.campaign_name}</Col>
                <Col className="text-end">${ic.net_amount}</Col>
              </Row>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Col>
      <Col xs={4}>
        <ListGroup>
          {invoiceCampaigns.map((ic, index) => (
            <ListGroupItem
              key={index}
              onClick={
                () =>
                  setassigned({
                    ...assigned,
                    [selected.name]: [...(assigned[selected.name] || []), ic],
                  })
                // setselected({
                //   ...selected,
                //   campaigns: [...selected.campaigns, ic],
                // })
              }
            >
              <Row>
                <Col>{ic.campaign_name}</Col>
                <Col className="text-end">${ic.net_amount}</Col>
              </Row>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Col>
    </Row>
  )
}

function AllCostsTable({ setting }) {
  const { costsErp, invoices = [], month = '', setFile = () => {} } = setting
  console.log(month)

  // const refinedHeader = useMemo(
  //   () =>
  //     costsErp[0]
  //       ? costsErp[0]
  //           .filter((c, index) => !costsErp[1].every((ce) => !ce[index]))
  //           .map((c) => c[5])
  //       : [],
  //   [costsErp]
  // )

  // const refinedErp = useMemo(
  //   () =>
  //     costsErp[1]
  //       ? costsErp[1].slice(0, 100).reduce(
  //           (prev, cur) => [
  //             ...prev,
  //             cur.reduce(
  //               (p, c, index) =>
  //                 c
  //                   ? {
  //                       ...p,
  //                       [costsErp[0][index][5]]: c,
  //                     }
  //                   : { ...p },
  //               {
  //                 付款方式: 'T',
  //                 狀態: 'OPEN',
  //                 數量: '1',
  //                 單位: 'SET',
  //                 財產類別: 'N',
  //                 '外加/內含(稅)': 2,
  //                 稅率: 0,
  //                 匯率: 1,
  //                 憑證類別: 'CERTIFICATE',
  //                 扣抵種類: 'F',
  //                 外加稅: 'Y',
  //                 發票格式: 22,
  //                 扣抵代號: 3,
  //                 課稅別: 3,
  //               }
  //             ),
  //           ],
  //           []
  //         )
  //       : [],
  //   [costsErp]
  // )

  console.log(invoices)
  const filteredrecord = useMemo(() => {
    if (!invoices) return []
    const reduced = invoices
      .filter(
        (r) =>
          !month ||
          moment(r.billing_period)
            .startOf('month')
            .subtract(1, 'months')
            .format('yyyyMM') === month
      )
      .reduce((prev, cur) => {
        const merged = cur.campaigns.data.reduce((prevc, c) => {
          const project =
            c.campaign_name && c.campaign_name.indexOf('【') !== -1
              ? c.campaign_name.substring(
                  c.campaign_name.indexOf('【') + 1,
                  c.campaign_name.indexOf('】')
                )
              : ''
          const exist = prevc.find(
            (p) => p.project === project && p.adaccount === cur.adaccount
          )
          if (exist) {
            return prevc.map((p) => {
              if (project === 'ATIA2404002') {
                console.log(`${project} ${cur.adaccount}`)
                console.log(parseFloat(p.單價))
                console.log(parseFloat(c.billed_amount_details.total_amount))
                console.log(
                  parseFloat(p.單價) +
                    parseFloat(c.billed_amount_details.total_amount)
                )
              }
              return p.project === project && p.adaccount === cur.adaccount
                ? {
                    ...p,
                    單價:
                      parseFloat(p.單價) +
                      parseFloat(
                        c.billed_amount_details.total_amount.replace(',', '')
                      ),
                    幣別:
                      parseFloat(p.幣別) +
                      parseFloat(
                        c.billed_amount_details.currency.replace(',', '')
                      ),
                    原幣金額:
                      parseFloat(p.原幣金額) +
                      parseFloat(
                        c.billed_amount_details.total_amount.replace(',', '')
                      ),
                    原幣稅額:
                      parseFloat(p.原幣稅額) +
                      parseFloat(
                        c.billed_amount_details.tax_amount.replace(',', '')
                      ),
                    帳上金額:
                      parseFloat(p.帳上金額) +
                      parseFloat(
                        c.billed_amount_details.total_amount.replace(',', '')
                      ),
                    帳上稅額:
                      parseFloat(p.帳上稅額) +
                      parseFloat(
                        c.billed_amount_details.tax_amount.replace(',', '')
                      ),
                  }
                : p
            })
          }
          return [
            ...prevc,
            {
              ...c,
              project,
              billing_period: cur.billing_period,
              adaccount: cur.adaccount,
              單價: parseFloat(
                c.billed_amount_details.total_amount.replace(',', '')
              ),
              幣別: parseFloat(
                c.billed_amount_details.currency.replace(',', '')
              ),
              原幣金額: parseFloat(
                c.billed_amount_details.total_amount.replace(',', '')
              ),
              原幣稅額: parseFloat(
                c.billed_amount_details.tax_amount.replace(',', '')
              ),
              帳上金額: parseFloat(
                c.billed_amount_details.total_amount.replace(',', '')
              ),
              帳上稅額: parseFloat(
                c.billed_amount_details.tax_amount.replace(',', '')
              ),
            },
          ]
        }, [])
        // const map = cur.campaigns.data.map((c) => {
        //   const project =
        //     c.campaign_name && c.campaign_name.indexOf('【') !== -1
        //       ? c.campaign_name.substring(
        //           c.campaign_name.indexOf('【') + 1,
        //           c.campaign_name.indexOf('】')
        //         )
        //       : ''
        //   return {
        //     ...c,
        //     project,
        //     billing_period: cur.billing_period,
        //     adaccount: cur.adaccount,
        //   }
        // })
        return [...prev, ...merged]
      }, [])
    const erp =
      costsErp && costsErp[1]
        ? costsErp[1].map((ce) =>
            ce.reduce(
              (prev, cur, j) =>
                prev[costsErp[0][j][5]]
                  ? prev
                  : {
                      ...prev,
                      [costsErp[0][j][5]]: cur,
                    },
              {}
            )
          )
        : []
    return reduced.map((r, i) => {
      const target = erp.find((e) => r.project === e['專案代號']) || {}
      const no = `SOME${moment(r.billing_period)
        .endOf('month')
        .format('yyyyMMDD')}${i < 99 ? '0' : ''}${i < 9 ? '0' : ''}${i + 1}`
      return {
        ...r,
        ...target,
        驗收單號: no,
        外部資料鍵值: `${i < 99 ? '0' : ''}${i < 9 ? '0' : ''}${i + 1}`,
        資料所屬月份: moment(r.billing_period)
          .startOf('month')
          .subtract(1, 'months')
          .format('yyyyMM'),
        憑證日期: moment(r.billing_period).endOf('month').format('yyyy/MM/DD'),
        驗收日期: moment(r.billing_period).endOf('month').format('yyyy/MM/DD'),
        預計付款日: moment(r.invoice_date).endOf('month').format('yyyy/MM/DD'),
        申請人員: target['專案負責人'] || '',
        廠商代號: target['客戶代碼'] || '',
        '品名/規格': target['訂單備註'] || '',
        說明: r.adaccount,
        // 單價: r.billed_amount_details.total_amount,
        // 幣別: r.billed_amount_details.currency,
        // 原幣金額: r.billed_amount_details.total_amount,
        // 原幣稅額: r.billed_amount_details.tax_amount,
        // 帳上金額: r.billed_amount_details.total_amount,
        // 帳上稅額: r.billed_amount_details.tax_amount,
        部門: 50400,
        付款條件: 'PM30',
        付款方式: 'T',
        狀態: 'OPEN',
        數量: '1',
        單位: 'SET',
        財產類別: 'N',
        '外加/內含(稅)': 2,
        稅率: 0,
        匯率: 1,
        憑證類別: 'CERTIFICATE',
        扣抵種類: 'F',
        外加稅: 'Y',
        發票格式: 22,
        扣抵代號: 3,
        課稅別: 3,
      }
    })
  }, [invoices, costsErp, month])

  const fields = [
    { label: '驗收單號' },
    { label: '驗收日期' },
    { label: '預計付款日' },
    { label: '申請人員' },
    { label: '廠商代號' },
    { label: '部門' },
    { label: '付款條件' },
    { label: '付款方式' },
    { label: '申購單號', hide: true },
    { label: '專案代號' },
    { label: '備註' },
    { label: '狀態' },
    { label: '外部資料鍵值' },
    { label: '編號' },
    { label: '品名/規格' },
    { label: '說明' },
    { label: '專案代號' },
    { label: '專案分攤群組', hide: true },
    { label: '數量' },
    { label: '單位' },
    { label: '單價' },
    { label: '歸屬部門' },
    { label: '分攤群組', hide: true },
    { label: '財產類別' },
    { label: '外加/內含(稅)' },
    { label: '稅率' },
    { label: '幣別' },
    { label: '匯率' },
    { label: '原幣金額' },
    { label: '原幣稅額' },
    { label: '帳上金額' },
    { label: '帳上稅額' },
    { label: '工單製令', hide: true },
    { label: '會計科目' },
    { label: '資產編號', hide: true },
    { label: '憑證類別' },
    { label: '憑證部門' },
    { label: '發票號碼', hide: true },
    { label: '其他憑證號碼' },
    { label: '憑證日期' },
    { label: '扣抵種類' },
    { label: '外加稅' },
    { label: '銷售人' },
    { label: '銷售人統一編號', hide: true },
    { label: '資料所屬月份' },
    { label: '進口免稅貨物/勞務', hide: true },
    { label: '發票格式' },
    { label: '載具流水號', hide: true },
    { label: '扣抵代號' },
    { label: '課稅別' },
    { label: '備註', hide: true },
    { label: '業務別', hide: true },
    { label: '折退原因', hide: true },
    { label: '租任期別', hide: true },
    { label: 'WHT收入種類', hide: true },
    { label: 'WHT稅率(%)', hide: true },
    { label: 'WHT說明', hide: true },
    { label: 'WHT稅額', hide: true },
    { label: '銷售人公司地址', hide: true },
    { label: '海關代徵營業稅', hide: true },
    { label: '銷售人名稱', hide: true },
  ]

  useEffect(() => {
    setFile([
      fields.filter((f) => !f.hide).map((f) => f.label),
      ...filteredrecord.map((record) =>
        fields.filter((f) => !f.hide).map((f) => record[f.label])
      ),
    ])
  }, [filteredrecord])

  return (
    <Row
      className="text-lucaDark ps-1 overflow-scroll scrollbarShow scrollbarShowBottom"
      style={{
        height: '68vh',
      }}
    >
      <Accordion defaultActiveKey="1" className="mb-5 MessageRecord">
        <Accordion.Item eventKey="0">
          <Accordion.Header title="點 擊 以 收 合">
            <div className="d-flex w-100">
              <div className="mx-auto">
                <h6 className="text-lucaDark mb-1 text-center">
                  代入利潤表設定
                </h6>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {/* <AssignTable setting={setting} /> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Col className="overflow-scroll">
        <Row
          className="p-0 mx-0 w-100 justift-content-end flex-nowrap"
          style={{
            height: '40px',
          }}
        >
          {/* <Col xs={2} className="py-1 d-flex colBorder App-oneLineEllipsis">
            <span className="d-flex w-100 App-oneLineEllipsis pe-0">
              活動名稱
            </span>
          </Col>
          <Col xs={2} className="py-1 d-flex colBorder App-oneLineEllipsis">
            <span className="d-flex w-100 App-oneLineEllipsis pe-0">
              發票金額
            </span>
          </Col> */}
          {fields
            .filter((f) => !f.hide)
            .map(({ label }, i) => (
              <Col
                xs={2}
                key={i}
                className="py-1 d-flex colBorder App-oneLineEllipsis"
              >
                <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                  {label}
                </span>
              </Col>
            ))}
        </Row>
        {filteredrecord.slice(0, 50).map((rf, i) => (
          <Row
            key={i}
            className="p-0 mx-0 w-100 justift-content-end flex-nowrap"
          >
            {/* {filteredrecord[rf[0]] ? (
              <>
                <Col
                  xs={2}
                  className="py-1 d-flex colBorder App-oneLineEllipsis"
                >
                  <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                    {filteredrecord[rf[0]].campaign_name}
                  </span>
                </Col>
                <Col
                  xs={2}
                  className="py-1 d-flex colBorder App-oneLineEllipsis"
                >
                  <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                    {filteredrecord[rf[0]].billed_amount_details.total_amount}
                  </span>
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={2}
                  className="py-1 d-flex colBorder App-oneLineEllipsis"
                >
                  <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                    發票內容
                  </span>
                </Col>
                <Col
                  xs={2}
                  className="py-1 d-flex colBorder App-oneLineEllipsis"
                >
                  <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                    發票內容
                  </span>
                </Col>
              </>
            )} */}
            {fields
              .filter((f) => !f.hide)
              .map(({ label }, j) => (
                <Col
                  key={`${i}_${j}`}
                  xs={2}
                  className="py-1 d-flex colBorder App-oneLineEllipsis"
                >
                  {rf[label] ? (
                    <span className="d-flex App-oneLineEllipsis pe-0">
                      {rf[label]}
                    </span>
                  ) : (
                    <span className="App-oneLineEllipsis">- -</span>
                  )}
                </Col>
              ))}
          </Row>
        ))}
      </Col>
    </Row>
  )
}

AllCostsTable.propTypes = {
  setting: PropTypes.shape().isRequired,
}

AssignTable.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AllCostsTable
